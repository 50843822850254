html,
body{
	position: relative;
	color: @color-flavor;
	font-family: @standard;
	font-weight: @light;
	font-size: @base-font-size;
	line-height: @base-line-height;	
	text-align: center;
	background-color: @color-salt;
}

.master{
	width: 100%;
	position: relative;
	min-height: 100%;
	overflow: hidden;
	z-index: 0;

	.section {
		padding: @margin * 2 0;
		width: 100%;
		float: left;
		position: relative;
	}
}

.thisis {
	.hispicture {
		height: 175px;
		width: 175px;
		border-radius: 100%;
		overflow: hidden;
		display: block;
		margin: @margin auto;
	}
	h1 {
		padding: 0 @margin;
		font-family: @heavy;
		font-size: 15vw;
		text-transform: uppercase;
		margin: @margin * 3 0;

		@media @mobile-min {
			font-size: 10vw;
		}

		& > span {
			display: block;
		}

		span:last-of-type {
			text-transform: lowercase;
			font-size: 30px;
		}
	}
}

.concentrate {
	padding-left: @margin !important;
	padding-right: @margin !important;

	.concentrate_interior {
		overflow: hidden;
		position: relative;

		p {
			display: inline-block;
			padding: @margin;
			background-color: @color-salt;

			&:before, &:after {
				content: '';
				height: 1px;
				width: 100vw;
				top: 50%;
				position: absolute;
				background-color: @color-flavor;
				display: block;
				z-index: -1;
				margin-top: -6px;
			}

			&:before {
				left: 0;
			}

			&:after {
				right: 0;
			}

			span {
				display: block;
				font-family: @swash_light;
				font-size: 18px;
			}
		}
	}

}

.block {
	cursor: pointer;

	& > span {
		font-family: @heavy;
		font-size: 20px;
		line-height: 30px;
		display: block;
		text-transform: uppercase;
		letter-spacing: 8px;
	}

	p, a {
		margin: 0;
		display: block;
		color: @color-flavor;
		text-decoration: none !important;
		margin: -5px 0 10px;

		&:hover, &:active {
			text-decoration: none !important;

			span {
				text-shadow: 2px 2px 0px @color-salt, 3px 3px 0px @color-flavor, 5px 0px 0px @color-salt;
			}
		}

		@media @mobile-min {
			margin: 0px 0 10px;
		}

		span {
			font-family: @swash;
			font-size: 60px;
			line-height: 90px;
			position: relative;
			transition: .2s ease all;
			transition-delay: .1s;
			text-shadow: 2px 2px 0px @color-salt, 3px 3px 0px @color-flavor, 5px 0px 0px @color-salt;

			@media @mobile-min {
				text-shadow: 0px 0px 0px @color-salt, 0px 0px 0px @color-flavor, 5px 0px 0px @color-salt;
			}

			&:before {
				position: absolute;
				content: '';
				bottom: 15px;
				width: 100%;
				border-bottom: 1px dotted @color-flavor;
				z-index: -1;
			}

			& + span {
				margin-top: @margin; 
			}
		}
	}
}







.waveform_interior {
	transition: all .5s ease;

	.waveform,
	.playpause {
		transform: translateY(20px);
		opacity: 0;
		visibility: hidden;
		transition: 
			transform .5s ease,
			opacity .5s ease,
			visibility .5s ease;
	}

	.waveform {
		position: absolute !important;
		left: 0;
		width: 100%;
		bottom: 75px;
		transition-delay: .5s;
		overflow: hidden !important;

		wave {
			overflow: hidden !important;
		}		
	}

	.playpause {
		height: 50px;
		width: 50px;		
		position: absolute;
		bottom: 25px;
		left: 50%;
		margin-left: -25px;
		transition-delay: .75s;

		i {			
			content: '';
			height: 10px;
			width: 10px;
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -5px 0 0 -5px;
			transition: all .25s ease;
			border-radius: 50%;
			background-color: @color-flavor;
			border: 1px solid @color-flavor;
		}
	}


	&.showing {
		padding-bottom: 150px;

		.playpause,
		.waveform {
			transform: translateY(0);
			opacity: 1;
			visibility: visible;
		}
	}

	&.playing {
		.playpause i {
			animation: none;
		}
	}

	&.paused {
		.playpause i {
			// animation: blink 1s steps(3) infinite alternate;
			animation: blink 2s infinite;
		}
	}
}

@keyframes blink{
	0%, 49% {
    background-color: @color-salt;
  }
  50%, 100% {
    background-color: @color-flavor;
  }
}

.what {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	flex-direction: column;

	@media @mobile-min {
		flex-direction: row;
	}
		
	& > div {
		flex: 1;
		flex-grow: 0;
		flex-shrink: 0;
		position: relative;
		float: left;
		padding: @margin @margin * 2;

		@media @mobile-min {
			padding: @margin * 2;
		}
	}

	.voice {

	}

	.and {
		font-family: @swash_light;
		font-size: 82px;
		line-height: 100px;

		@media @mobile-min {
			font-size: 122px;
			line-height: 165px; 
		}
	}

	.produce {

	}

}

.contact {
	p {
		font-family: @heavy;
		font-size: 20px;
		margin: @margin 0;
	}

	a {
		display: block;
		height: 100px;
		width: 100px;
		background-color: @color-flavor;
		border-radius: 50%;
		position: relative;
		margin: @margin * 2 auto;
		// background-size: 50% 50%;
		background-size: 70%;
		background-position: center center;
		background-repeat: no-repeat;

		&:before{
			content: '';
			position: absolute;
			height: 106px;
			width: 106px;
			top: -3px;
			left: -3px;
			border-radius: 50%;
			border: 1px dotted @color-flavor;
			transition: all .2s ease;
		}

		&:hover:before, &:active:before {
			height: 120px;
			width: 120px;
			top: -10px;
			left: -10px;
		}
	}

	.contact_mail {
		background-image: url(../img/contact_mail.png);
	}

	.contact_phone {
		background-image: url(../img/contact_phone.png);
	}

}

.colophon {
	background-color: @color-flavor;
	padding: @margin / 2 !important;

	.colophon_interior {
		display: block;
		height: 50px;
		background-image: url(../img/mbm.png);
		background-position: center;
		background-repeat: no-repeat;
		background-size: contain;
	}

}





















