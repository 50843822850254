// @font-face {
//     font-family: 'NAME_OF_YOUR_WEBFONT';
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot');
//     src: url('PATH/TO/YOUR_WEBFONT_HERE.eot?#iefix') format('embedded-opentype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.woff') format('woff'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.ttf') format('truetype'),
//          url('PATH/TO/YOUR_WEBFONT_HERE.svg#NAME_OF_YOUR_WEBFONT') format('svg');
//     font-weight: normal;
//     font-style: normal;
// }

@font-face {
    font-family: 'League Spartan';
    src: url('../type/leaguespartan-bold.eot');
    src: url('../type/leaguespartan-bold.eot?#iefix') format('embedded-opentype'),
         url('../type/leaguespartan-bold.woff2') format('woff2'),
         url('../type/leaguespartan-bold.woff') format('woff'),
         url('../type/leaguespartan-bold.ttf') format('truetype'),
         url('../type/leaguespartan-bold.svg#league_spartanbold') format('svg');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'bodoni_4848-bold-italic';
    src: url('../type/bodoni-48-bold-italic-webfont.woff2') format('woff2'),
         url('../type/bodoni-48-bold-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'bodoni_4848-book-italic';
    src: url('../type/bodoni-48-book-italic-webfont.woff2') format('woff2'),
         url('../type/bodoni-48-book-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}